import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <h1>网站建设中，敬请期待。。。</h1>
    
    <footer>
      <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2023000447号-1</a>
    </footer>
  </Layout>
)

export default IndexPage
